var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "card card-custom"
  }, [_c('div', {
    staticClass: "card-header flex-wrap border-0 pt-6 pb-0"
  }, [_c('div', {
    staticClass: "card-title"
  }, [_c('h3', {
    staticClass: "card-label"
  }, [_vm._v(" " + _vm._s(_vm.$t("Atlasposting Staff")) + " "), _c('span', {
    staticClass: "d-block text-muted pt-2 font-size-sm"
  }, [_vm._v("User management")])])]), _c('div', {
    staticClass: "card-toolbar"
  }, [_c('euro-input', {
    staticClass: "min-w-300px mb-0 mr-0",
    attrs: {
      "placeholder": _vm.$t('Search'),
      "debounce": 300
    },
    model: {
      value: _vm.filter,
      callback: function callback($$v) {
        _vm.filter = $$v;
      },
      expression: "filter"
    }
  }), _c('button', {
    staticClass: "btn btn-light-primary font-weight-bolder",
    attrs: {
      "type": "button",
      "aria-haspopup": "true",
      "aria-expanded": "false"
    },
    on: {
      "click": _vm.createEuropostingStaff
    }
  }, [_c('span', {
    staticClass: "svg-icon svg-icon-md"
  }, [_c('inline-svg', {
    attrs: {
      "src": "/media/svg/icons/Communication/Add-user.svg"
    }
  })], 1), _vm._v(" " + _vm._s(_vm.$t("Staff")) + " ")])], 1)]), _c('div', {
    staticClass: "card-body"
  }, [_c('datatable', {
    ref: "datatableRef",
    attrs: {
      "table-props": _vm.tableProps,
      "total": _vm.total,
      "options": _vm.tableOptions,
      "per-page-options": _vm.perPageOptions
    },
    on: {
      "update:options": function updateOptions($event) {
        _vm.tableOptions = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "cell.avatar",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('div', {
          staticClass: "d-flex align-items-center"
        }, [_c('Avatar', {
          staticClass: "mr-2",
          attrs: {
            "avatar-text": _vm.getInitials(item.first_name, item.last_name)
          }
        }), _c('div', {
          staticClass: "ml-3"
        }, [_c('div', {
          staticClass: "font-weight-bold",
          style: {
            wordBreak: 'break-word'
          }
        }, [_vm._v(" " + _vm._s("".concat(item === null || item === void 0 ? void 0 : item.first_name, " ").concat(item === null || item === void 0 ? void 0 : item.last_name)) + " ")]), _c('div', {
          staticClass: "text-muted font-size-xs"
        }, [_vm._v(_vm._s(item.email))])])], 1)];
      }
    }, {
      key: "cell.groups",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [item.groups.length > 0 ? _c('div', _vm._l(item.groups, function (group) {
          return _c('div', {
            key: group.id
          }, [_c('span', {
            staticClass: "label label-sm mb-1 label-inline font-weight-bolder text-capitalize label-light-warning"
          }, [_vm._v(" " + _vm._s(group.name) + " ")])]);
        }), 0) : _c('div')];
      }
    }, {
      key: "cell.is_invite_accepted",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_c('span', {
          staticClass: "label label-inline font-weight-bold text-capitalize",
          class: {
            'label-light-success': item.is_invite_accepted,
            'label-light-danger': !item.is_invite_accepted
          },
          style: {
            minWidth: '14ch'
          }
        }, [_vm._v(_vm._s(item.is_invite_accepted ? _vm.$t("ACCEPTED").toLowerCase() : _vm.$t("NOT ACCEPTED").toLowerCase()))])];
      }
    }, {
      key: "cell.is_active",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [_c('span', {
          staticClass: "label label-inline font-weight-bold text-capitalize",
          class: {
            'label-light-success': item.is_active,
            'label-light-danger': !item.is_active
          },
          style: {
            minWidth: '11ch'
          }
        }, [_vm._v(_vm._s(item.is_active ? _vm.$t("ACTIVE").toLowerCase() : _vm.$t("NOT ACTIVE").toLowerCase()))])];
      }
    }, {
      key: "cell.date_joined",
      fn: function fn(_ref5) {
        var item = _ref5.item;
        return [_c('span', {
          staticClass: "font-weight-bolder text-muted"
        }, [_vm._v(_vm._s(_vm.DateService.format(item.date_joined, _vm.dateFormat)))])];
      }
    }, {
      key: "cell.actions",
      fn: function fn(_ref6) {
        var item = _ref6.item;
        return [_c('div', {
          staticClass: "text-nowrap"
        }, [_c('b-button', {
          staticClass: "btn btn-icon btn-light btn-hover-primary btn-sm ml-3",
          attrs: {
            "title": item.is_invite_accepted ? _vm.$t('Send password reset email') : _vm.$t('Send new invite email')
          },
          on: {
            "click": function click($event) {
              return _vm.sendNewInviteMail(item);
            }
          }
        }, [_c('span', {
          staticClass: "svg-icon svg-icon-md svg-icon-primary"
        }, [_c('inline-svg', {
          attrs: {
            "src": "/media/svg/icons/Communication/Send.svg",
            "title": item.is_invite_accepted ? _vm.$t('Send password reset email') : _vm.$t('Send new invite email')
          }
        })], 1)]), _c('b-button', {
          staticClass: "btn btn-icon btn-light btn-hover-primary btn-sm ml-3",
          attrs: {
            "title": _vm.$t('Go to physical person profile')
          },
          on: {
            "click": function click($event) {
              return _vm.goToPhysicalPerson(item);
            }
          }
        }, [_c('span', {
          staticClass: "svg-icon svg-icon-md svg-icon-primary"
        }, [_c('inline-svg', {
          attrs: {
            "src": "/media/svg/icons/General/User.svg",
            "title": _vm.$t('Go to physical person profile')
          }
        })], 1)]), _c('b-button', {
          staticClass: "btn btn-icon btn-light btn-hover-primary btn-sm ml-3",
          attrs: {
            "title": _vm.$t('Change user groups')
          },
          on: {
            "click": function click($event) {
              _vm.showModal(item);
              _vm.selectedOptions(item);
            }
          }
        }, [_c('span', {
          staticClass: "svg-icon svg-icon-md svg-icon-primary"
        }, [_c('inline-svg', {
          attrs: {
            "src": "/media/svg/icons/Communication/Group.svg",
            "title": _vm.$t('Change user groups')
          }
        })], 1), _c('b-modal', {
          ref: "change-groups-modal",
          on: {
            "hidden": _vm.onModalHidden
          },
          scopedSlots: _vm._u([{
            key: "modal-header",
            fn: function fn() {
              return [_c('h4', {
                staticClass: "mb-0"
              }, [_vm._v(_vm._s(_vm.$t("Change groups")))])];
            },
            proxy: true
          }, {
            key: "modal-footer",
            fn: function fn() {
              return [_c('b-button', {
                staticClass: "btn btn-success mr-2",
                on: {
                  "click": function click($event) {
                    return _vm.submit(_vm.user_item);
                  }
                }
              }, [_vm._v(" " + _vm._s(_vm.$t("Save Changes")))])];
            },
            proxy: true
          }], null, true)
        }, [_c('validation-provider', {
          attrs: {
            "name": "groups",
            "vid": "groups"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref7) {
              var errors = _ref7.errors;
              return [_c('euro-select', {
                attrs: {
                  "multiselect-props": {
                    options: _vm.staff_group_list,
                    'track-by': 'id',
                    label: 'name',
                    multiple: true,
                    searchable: true,
                    placeholder: _vm.$t('Select Group')
                  },
                  "error-messages": errors
                },
                model: {
                  value: _vm.userGroup,
                  callback: function callback($$v) {
                    _vm.userGroup = $$v;
                  },
                  expression: "userGroup"
                }
              })];
            }
          }], null, true)
        })], 1)], 1)], 1)];
      }
    }], null, true)
  })], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }