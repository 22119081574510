<template>
  <div class="card card-custom">
    <div class="card-header flex-wrap border-0 pt-6 pb-0">
      <div class="card-title">
        <h3 class="card-label">
          {{ $t("Atlasposting Staff") }}
          <span class="d-block text-muted pt-2 font-size-sm">User management</span>
        </h3>
      </div>
      <div class="card-toolbar">
        <euro-input v-model="filter" :placeholder="$t('Search')" class="min-w-300px mb-0 mr-0" :debounce="300">
        </euro-input>
        <button type="button" class="btn btn-light-primary font-weight-bolder" aria-haspopup="true" aria-expanded="false"
          @click="createEuropostingStaff">
          <span class="svg-icon svg-icon-md">
            <inline-svg src="/media/svg/icons/Communication/Add-user.svg" />
          </span>
          {{ $t("Staff") }}
        </button>
      </div>
    </div>
    <div class="card-body">
      <datatable ref="datatableRef" :table-props="tableProps" :total="total" :options.sync="tableOptions"
        :per-page-options="perPageOptions">
        <template #[`cell.avatar`]="{ item }">
          <div class="d-flex align-items-center">
            <Avatar :avatar-text="getInitials(item.first_name, item.last_name)" class="mr-2" />
            <div class="ml-3">
              <div class="font-weight-bold" :style="{ wordBreak: 'break-word' }">
                {{ `${item?.first_name} ${item?.last_name}` }}
              </div>
              <div class="text-muted font-size-xs">{{ item.email }}</div>
            </div>
          </div>
        </template>
        <template #[`cell.groups`]="{ item }">
          <div v-if="item.groups.length > 0">
            <div v-for="group in item.groups" :key="group.id">
              <span class=" label label-sm mb-1 label-inline font-weight-bolder text-capitalize label-light-warning">
                {{ group.name }}
              </span>
            </div>
          </div>
          <div v-else></div>
        </template>

        <template #[`cell.is_invite_accepted`]="{ item }">
          <span class="label label-inline font-weight-bold text-capitalize" :class="{
            'label-light-success': item.is_invite_accepted,
            'label-light-danger': !item.is_invite_accepted,
          }" :style="{ minWidth: '14ch' }">{{ item.is_invite_accepted ? $t("ACCEPTED").toLowerCase() :
  $t("NOT ACCEPTED").toLowerCase() }}</span>
        </template>

        <template #[`cell.is_active`]="{ item }">
          <span class="label label-inline font-weight-bold text-capitalize" :class="{
            'label-light-success': item.is_active,
            'label-light-danger': !item.is_active,
          }" :style="{ minWidth: '11ch' }">{{ item.is_active ? $t("ACTIVE").toLowerCase()
  : $t("NOT ACTIVE").toLowerCase() }}</span>
        </template>

        <template #[`cell.date_joined`]="{ item }">
          <span class="font-weight-bolder text-muted">{{ DateService.format(item.date_joined, dateFormat) }}</span>
        </template>

        <template #[`cell.actions`]="{ item }">
          <div class="text-nowrap">
            <b-button class="btn btn-icon btn-light btn-hover-primary btn-sm ml-3"
              :title="item.is_invite_accepted ? $t('Send password reset email') : $t('Send new invite email')"
              @click="sendNewInviteMail(item)">
              <span class="svg-icon svg-icon-md svg-icon-primary">
                <inline-svg src="/media/svg/icons/Communication/Send.svg" :title="item.is_invite_accepted ? $t('Send password reset email') : $t('Send new invite email')
                  " />
              </span>
            </b-button>

            <b-button class="btn btn-icon btn-light btn-hover-primary btn-sm ml-3"
              :title="$t('Go to physical person profile')" @click="goToPhysicalPerson(item)">
              <span class="svg-icon svg-icon-md svg-icon-primary">
                <inline-svg src="/media/svg/icons/General/User.svg" :title="$t('Go to physical person profile')" />
              </span>
            </b-button>

            <b-button class="btn btn-icon btn-light btn-hover-primary btn-sm ml-3" :title="$t('Change user groups')"
              @click=" showModal(item); selectedOptions(item);">
              <span class="svg-icon svg-icon-md svg-icon-primary">
                <inline-svg src="/media/svg/icons/Communication/Group.svg" :title="$t('Change user groups')" />
              </span>

              <b-modal ref="change-groups-modal" @hidden="onModalHidden">
                <template #modal-header>
                  <h4 class="mb-0">{{ $t("Change groups") }}</h4>
                </template>
                <validation-provider v-slot="{ errors }" name="groups" vid="groups">
                  <euro-select v-model="userGroup" :multiselect-props="{
                    options: staff_group_list,
                    'track-by': 'id',
                    label: 'name',
                    multiple: true,
                    searchable: true,
                    placeholder: $t('Select Group'),
                  }" :error-messages="errors">
                  </euro-select>
                </validation-provider>
                <template #modal-footer>
                  <b-button class="btn btn-success mr-2" @click="submit(user_item)"> {{ $t("Save Changes") }}</b-button>
                </template>
              </b-modal>
            </b-button>
          </div>
        </template>
      </datatable>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import icons from "@/core/config/icons";
import UsersService from "@/core/services/users.service";
import DateService from "@/core/services/date.service";
import GroupService from "@/core/services/group/group.service";
import Datatable from "@/view/components/tables/Datatable.vue";
import InvitationService from "@/core/services/invitation/invitation.service";
import { mapGetters } from "vuex";
import { backendErrorSwal } from "@/core/helpers/swal";
import Swal from "sweetalert2";
import { perPageOptions, tableOptions } from "@/core/config/datatable.config.js"


export default {
  components: {
    Datatable,
  },
  data() {
    return {
      fields: [
        { label: this.$t("Email"), key: "avatar" },
        { label: this.$t("Groups"), key: "groups", sortable: false },
        { label: this.$t("Status"), key: "is_active", sortable: true },
        { label: this.$t("Date Joined"), key: "date_joined", sortable: true },
        { label: this.$t("Invite"), key: "is_invite_accepted", sortable: true },
        { label: this.$t("Actions"), key: "actions", class: "align-end col-actions" },
      ],
      filter: "",
      total: 0,
      tableOptions: tableOptions,
      perPageOptions: perPageOptions,
      icons,
      DateService: DateService,
      staff_group_list: [],
      userGroup: [],
      isSaving: false,
      user_item: null,
    };
  },

  computed: {
    ...mapGetters("user", ["dateFormat"]),

    tableProps() {
      return {
        items: this.itemProvider,
        fields: this.fields,
        responsive: true,
        filter: this.filter
      };
    },
  },
  mounted() {
    if (this.staff_group_list.length == 0) {
      GroupService.getAllNotPaginated().then(res => {
        this.staff_group_list = res.results;
      });
    }
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("Administration") },
      { title: this.$t("Users") },
      { title: this.$t("Atlasposting Staff") },
      { title: this.$t("Manage") },
    ]);
  },

  methods: {
    getInitials(first, last) {
      return `${first ? first.toUpperCase()[0] : ""}${last ? last.toUpperCase()[0] : ""}`;
    },
    itemProvider(ctx, callback) {
      UsersService.getApStaffUsers({
        perPage: ctx.perPage,
        page: ctx.currentPage,
        sortBy: ctx.sortBy || "date_joined",
        sortDesc: ctx.sortDesc,
        search: ctx.filter,
        fields: 'id,first_name,last_name,email,groups,is_invite_accepted,is_active,date_joined,persona'
      }).then(res => {
        this.total = res.count;
        callback(res.results);
      }).catch(() => callback([]));

      return null;
    },

    sendNewInviteMail(user) {
      InvitationService.resendInvitation(user.email).then(() => {
        Swal.fire("Mail sent", "", "success");
      }).catch(err => {
        console.error(err);
        backendErrorSwal(err, err?.response?.data?.detail);
      });
    },

    goToPhysicalPerson(user) {
      this.$router.push({ name: "detail-physical-person", params: { userID: user.persona } });
    },

    createEuropostingStaff() {
      this.$router.push({ name: "administration-staff-create" });
    },

    showModal(item) {
      this.$refs["change-groups-modal"].show();
      this.user_item = item;
    },
    onModalHidden() {
      this.item = null;
    },

    closeModal() {
      this.$refs["change-groups-modal"].hide();
    },
    selectedOptions(item) {
      this.userGroup = item.groups.map(el => el.id);
    },
    refresh() {
      this.$refs.datatableRef.refresh();
    },

    async submit(item) {
      this.isSaving = true;
      try {
        await UsersService.editUser(item.id, { groups: this.userGroup });
        this.closeModal();
        let title = this.$t("User updated");
        Swal.fire(this.$t("Success"), title, "success");
        this.refresh();
      } catch (err) {
        console.log(err);
        if (err?.response?.status === 400) {
          this.apiErrors = err.response.data;
          let message = err?.response?.data?.detail ?? this.$t("Failed to update groups");
          backendErrorSwal(err, message);
        }
      } finally {
        this.isSaving = false;
      }
    },
  },
};
</script>

<style scoped>
.btn.btn-outline-light {
  color: #fff;
  background: rgba(255, 255, 255, 0.2);
}

.btn.btn-outline-light i::before {
  color: #fff;
}

.btn.btn-outline-light:hover span,
.btn.btn-outline-light:hover i::before {
  color: var(--primary);
}
</style>
